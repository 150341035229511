import mixpanel from 'mixpanel-browser';
mixpanel.init('ae651ae6b98c121e6e2cb39895558d11');


let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
};

export let Mixpanel = actions;