
import React from 'react';
import styled from 'styled-components';
import logo from '../goodlogo.png';


const Logo = styled.div`
    width: 160px;
    height: auto;
    padding: 20px;
    box-sizing: border-box;

    @media(max-width: 720px) {
        width: 150px;

    }
`;

const LogoImage = styled.img`
width: 100%;
height: auto;
`
export function Header() {
    return (
        <div className='header-absolute'>   
            <Logo>
                <LogoImage src={logo} className="App-logo" alt="logo"></LogoImage>
            </Logo>

                
        </div>
    );
}