import React, {useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import { AudioElement } from '../components/audioElement';
import useSound from 'use-sound';
import closeIcon from '../x@2x.png';
import heroGuy from '../heroguy.png';
import yellow from '../yellow.png';
import pink from '../pink.png';
import blue from '../blue.png';
import playButton from '../play.png';
import pauseButton from '../pause.png';
import lines from '../lines.png';
import pinkDots from '../pink-dots.png';
import yellowDots from '../yellow-dots.png';
import waves from '../waves.png';
import audioWave from '../audio-wave.png';
import Lottie from "lottie-react";
import audioLottie from "../audio_lottie.json"
import { Mixpanel } from '../mixpanel';


const Background = styled.div`
    background: #FFF4F0;
    width:100%;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;


    .voice-bird {
        display: none;
    }

    .birdContainer1 {
        display: block;
        position: absolute;
        top: -4px;
        right: 141px;

        @media(max-width: 720px) {
            top: -10px;
            right: 95px;
        }
        @media(max-width: 520px) {
            top: -14px;
            right: 61px;
        }

        .birdImage {
            width: 66px;
            height: auto;

            @media(max-width: 720px) {
                width: 55px;
            }
            @media(max-width: 520px) {
                width: 47px;
            }
          
            &:hover {
              cursor: pointer;
            }
        }

        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            min-width: 110px;
            top: -111px;
            left: -18px;
            text-align: left;

            :before {
                content: "";
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                right: 50%;
                margin-right: -20px;
                bottom: -18px;
            }         
        }
        &:hover .child {
            display: block;
        }
    }
    .birdContainer2 {
        position: absolute;
        right: 205px;
        bottom: 142px;

        @media(max-width: 720px) {
            right: 138px;
            bottom: 100px;
        }
        @media(max-width: 520px) {
            right: 94px;
            bottom: 67px;
        }

        .birdImage {
            width: 117px;
            height: auto;

            @media(max-width: 720px) {
                width: 94px;
            }
            @media(max-width: 520px) {
                width: 70px;
            }
        }

        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            top: -85px;
            left: -88px;
            width: 120px;
            text-align: left;

            :before {
                content: "";
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                right: 50%;
                margin-right: -20px;
                bottom: -18px;
            }
                }

                &:hover .child {
                    display: block;
                }
            }
    .birdContainer3 {
        position: absolute;
        left: 225px;
        bottom: 101px;

        @media(max-width: 720px) {
            left: 154px;
            bottom: 70px;
        }
        @media(max-width: 520px) {
            left: 107px;
            bottom: 47px;
        }

        .birdImage {
            width: 87px;
            height: auto;

            @media(max-width: 720px) {
                width: 67px;
            }
            @media(max-width: 520px) {
                width: 54px;
            }
        }


        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            top: -114px;
            left: -64px;
            width: 120px;
            text-align: left;

            :before {
                content: "";
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                right: 50%;
                margin-right: -20px;
                bottom: -18px;
            }
                }

        &:hover .child {
            display: block;
        }
    }
    .birdContainer4 {
        position: absolute;
        bottom: 0px;
        left: 28%;

        @media(max-width: 1100px) {
            left: auto;
            right: 580px;
        }
        @media(max-width: 960px) {
            right: 510px;
        }
        @media(max-width: 768px) {
            left: 14%;
            right: auto;
        }
        @media(max-width: 720px) {
            bottom: -3px;
        }


        .birdImage {
            width: 215px;
            height: auto;

            @media(max-width: 720px) {
                width: 165px;
            }
            @media(max-width: 520px) {
                width: 128px;
            }
        }

        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            top: -94px;
            left: 64px;
            width: 110px;
            text-align: left;

            :before {
                content: "";
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                left: 50%;
                margin-left: -20px;
                bottom: -16px;
                transform: rotate(0deg);
            }
                }

        &:hover .child {
            display: block;
        }
    }
    .birdContainer5 {
        position: absolute;
        bottom: 0;
        right: 28%;

        @media(max-width: 960px) {
            right: 180px;
        }

        @media(max-width: 720px) {
            bottom: -3px;
        }

        .birdImage {
            width: 142px;
            height: auto;

            @media(max-width: 720px) {
                width: 112px;
            }
            @media(max-width: 520px) {
                width: 90px;
            }
        }

        @media(max-width: 768px) {
            right: 14%;
        }


        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            min-width: 120px;      
            top: -115px;
            left: -80px;
            text-align: left;

            :before {
                content: "";
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                right: 50%;
                margin-right: -20px;
                bottom: -18px;
            }
                }

                &:hover .child {
                    display: block;
                }
            }
    .birdContainer6 {
        position: absolute;
        bottom: 0px;
        right: 0;

        @media(max-width: 720px) {
            bottom: -6px;
        }

        .birdImage {
            width: 103px;
            height: auto;

            @media(max-width: 720px) {
                width: 70px;
            }
            @media(max-width: 520px) {
                width: 60px;
            }
        }

        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            min-width: 120px;
            top: -29px;
            left: -175px;
            text-align: left;

            :before {
                content: "";
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                right: -30px;
                margin-top: -10px;
                top: 50%;
                transform: rotate(-90deg);
                
            }  
        }
        &:hover .child {
            display: block;
        }
    }
    .birdContainer7 {
        position: absolute;
        top: -444px;
        right: 0;

        @media(max-width: 720px) {
            top: -390px;
        }
        @media(max-width: 520px) {
            top: -330px;
        }

        .birdImage {
            width: 130px;
            height: auto;

            @media(max-width: 720px) {
                width: 100px;
            }
            @media(max-width: 520px) {
                width: 83px;
            }
    
        }

        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            min-width: 110px;
            top: -75px;
            left: -39px;
            text-align: left;

            @media(max-width: 720px) {
                left: -56px;    
            }
            @media(max-width: 520px) {
                left: -72px;
            }

            :before {
                content: "";
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                right: 50%;
                margin-right: -20px;
                bottom: -18px;
            }
                }

                &:hover .child {
                    display: block;
                }
    }
    .birdContainer8 {
        position: absolute;
        left: -60px;
        top: -444px;

        @media(max-width: 720px) {
            top: -390px;
        }

        @media(max-width: 520px) {
            top: -330px;
        }

        .birdImage {
            @media(max-width: 720px) {
                width: 114px;
            }
            @media(max-width: 520px) {
                width: 105px;
            }
        }

        
        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            min-width: 120px;
            left: 153px;
            top: -1px;
            text-align: left;

            @media(max-width: 720px) {
                left: 140px;
            }

            :before {
                content: "";
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                left: -30px;
                margin-top: -10px;
                top: 50%;
                transform: rotate(-270deg);
            }
                }

                &:hover .child {
                    display: block;
                }
    }
    .birdContainer9 {
        position: absolute;
        left: 0px;
        bottom: 0px;

        @media(max-width: 720px) {
            bottom: -3px;
        }

        .birdImage {
            width: 180px;
            height: auto;

            @media(max-width: 720px) {
                width: 135px;
            }
            @media(max-width: 520px) {
                width: 112px;
            }
        }

        .child {
            display: none;
            background: #ffffff;
            padding: 15px;
            position: absolute;
            border-radius: 12px;
            left: 158px;
            top: 40px;
            min-width: 120px;
            text-align: left;

            @media(max-width: 720px) {
                left: 123px;
                top: 23px;
            }

            :before {
                content: "";
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #ffffff;
                position: absolute;
                left: -30px;
                margin-top: -10px;
                top: 50%;
                transform: rotate(-270deg);
            }

                }

                &:hover .child {
                    display: block;
                }
            }

    
` ;
  const BigCloud = styled.img`
    width: 140px;
    position: absolute;
    top: 150px;
    left: 200px;

    @media(max-width: 720px) {
        width: 102px;
        top: 90px;
        left: 20px;
    }
` ;

const Container = styled.div`
    padding: 50px 70px 40px;
    text-align: left;
    position: relative;
    z-index: 99;

    Heading1 {
        text-align: left;
    }

    @media(max-width: 880px) {
        padding-top: 80px;
        padding-right: 40px;
    
    }
    @media(max-width: 580px) {
          padding-left: 40px;  
    }
  ` ;

  const SectionVitamin = styled.div`
    padding-left:40px;
    padding-right: 40px;

    p {
        font-size: 24px;
        text-align: center;
        max-width: 870px;
        margin: 0 auto;
        padding-left: 30px;
        padding-right: 30px;

 
        @media(max-width: 760px) {
            font-size: 18px;
        }

        span {
            margin-bottom: 40px;
            display: block;
            text-align: left;

            @media(max-width: 620px) {
                text-align: center;
            }
        }
    }
    .flex-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        h3 {
            text-align: right;
            margin-top: 0;
            max-width: 383px;
            margin-right: 80px;

            @media(max-width: 760px) {
                margin-right: 30px;
            }

            
        }

        @media(max-width: 620px) {
            flex-direction: column;
            align-items: center;

            h3 {
               text-align: center;
               margin-right: 0;
            }
        }
    }
   
  ` ;
  const SectionHowItWorks = styled.div`
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 30px;
        padding-right: 30px;
  ` ;
const Heading1 = styled.h1`
        display: block;
        text-align: left;
        font-size: 78px;
        line-height: 90px;
        font-weight: 700;
        font-family: 'Eina02Bold';
        

        @media(max-width: 760px) {
            font-size: 36px;
        }
  ` ;


const Heading2 = styled.h2`
        font-size: 48px;
        font-weight: 700;
        font-family: 'Eina02Bold';
        max-width: 1210px;
        text-align: center;
        margin: 0 auto;

        @media(max-width: 760px) {
            font-size: 32px;

        }


  ` ;

const Modal = styled.div`
  
`;

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled.div`
  max-width: 560px;
  border-radius: 10px;
  background: rgba(255,255,255,1);
  position: relative;
  padding: 40px;

  @media(max-width: 760px) {
    margin: 0 20px;
    padding: 35px 25px;
  }

`;
const SectionSignUpForm = styled.div`
  background: #FDF4F0;
  padding: 90px;
  padding-left: 20px;
    padding-right: 20px;

    h2 {
        margin-bottom: 60px;
    }

  button {
    display: block;
    margin: 0 auto;

    @media(max-width: 660px) {
        width: 240px;
    }
  }

  @media(max-width: 1300px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const FormContainer = styled.div`

  border-radius: 30px;
  max-width: 1210px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  

   span {
    font-size: 22px;
    margin-bottom: 80px;
    margin-top: 10px;

    @media(max-width: 760px) {
        font-size: 18px;
    }
    @media(max-width: 665px) {
        display: block;
        margin-left 15px;
        margin-bottom: 30px;
    }
   }
   .RadioLabel {
    font-size: 18px;
    text-align: left;
    font-family: 'Eina02Bold';

   }
   p {
    text-align: center;
   }
`;
const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: auto;
  margin-top: -15px;
  margin-right: -15px;
  cursor: pointer;
`;

const YellowButton = styled.button`
    width: 382px;
    height: 80px;
    background: #FFDC00;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    color: #000000;

    @media(max-width: 1200px) {
        width: 274px;
        height: 65px;

    }
    @media(max-width: 760px) {
        width: 234px;
        height: 54px;
        font-size: 22px;
    }

    &:hover {
        opacity: 0.7;
    }
    &:focus, &:active {
        opacity: 1;
    }
    ` ;

const OutlineButton = styled.button`
width: 397px;
height: 60px;
text-align: center;
font-size: 24px;
font-weight: 600;
background: #000000;
margin: 30px 10px 0;
cursor: pointer;
border-radius: 50px;
color: #ffffff;
border: none;

@media(max-width: 760px) {
    font-size: 22px;
}
@media(max-width: 560px) {
    max-width: 320px;
    width: 100%;
}

&:disabled {
    opacity: 0.4;
}

&:active, &:hover {
    &:not([disabled]) {
        opacity: 0.7;
    }
}
` ;

const LinkAnchor = styled.a`
   
   text-decoration: none;
`;

  


const FormLabel = styled.label`
       text-align: left;
       margin-bottom: 20px;
       font-family: "Eina02Bold";
       font-size: 18px;
       margin-right: 15px;
       min-width: 560px;
       margin-left: 15px;


  `;
const FormSelect = styled.select`
    display: inline-block;
    height: 50px;
    border-radius: 0;
    background: transparent;
    color: #00CD7C;
    border: none;
    font-size: 18px;
    border-bottom: 2px solid #000000;
    margin-top: 10px;
    max-width: 425px;
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;


    @media(max-width: 885px) {
        max-width: 300px;
    }


        
  `;
 const InputLabel = styled.label`
       display: block;
       text-align: left;
       font-family: "Eina02Bold";
  `;

const Input = styled.input`
    height: 50px;
    border-radius: 10px;
    border: 1px solid #000000;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    box-sizing: border-box;
    max-width: 330px;
    width: 100%;
       
  `;
  const BetaForm = styled.form`
  text-algin: center;

  div {
    text-align: center;
  }

  label {
       font-family: "Eina02Bold";
       font-size: 16px;
       text-align: left;
       margin-left: 0;
       max-width: 330px;
       margin: 0 auto;
       min-width: 0;
  }
  select {
    font-size: 16px;
    color: #000000;
    margin-left: 0;
    max-width: 330px;
    margin: 0 auto;
  }


`;
  const MaxGrid = styled.div`
    max-width: 1512px;
    width: 100%;
    margin: 0 auto;
        
  `;
  const MaxGrid2 = styled.div`
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;
        
  `;
  const Section2 = styled.div`
    padding-top: 120px;
    padding-bottom: 50px;
    position: relative;

    @media(max-width: 1400px) {
        padding: 70px 40px 15px;
    }

        
  `;
  const SectionAudio = styled.div`
    position: relative;
    padding-top: 60px;
    padding-bottom: 160px;

    @media(max-width: 960px) {

        padding-bottom: 80px;
    }
    
`;

  const Hero = styled.img`
        max-width: 800px;
        width: 100%;
        height: auto;
        z-index: 9;

        @media(max-width: 1370px) {
            max-width: 700px;
        }
        @media(max-width: 1150px) {
            max-width: 600px;
        }
        @media(max-width: 1024px) {
            max-width: 600px;
        }
        @media(max-width: 1024px) {
            max-width: 510px;
        }
        @media(max-width: 880px) {
            max-width: 440px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
        @media(max-width: 660px) {
            height: 550px;
            width: auto;
            max-width: none;
            right: -140px;
        
        }
`;

  const BoldHeading2 = styled.h2`
        font-size: 26px;
        font-weight: 700;
        font-family: 'Eina02Bold';
        max-width: 960px;
        margin: 20px auto 30px;
        position: relative;

        @media(max-width: 960px) {
            font-size: 20px;
            
        }
        @media(max-width: 560px) {
          max-width: 300px;
          
      }

  ` ;
  const Heading3 = styled.h3`
        font-size: 68px;
        font-family: 'Eina02Regular';
        font-weight: 400;
        text-transform: uppercase;

        span {
            font-size: 68px;
        }

        @media(max-width: 760px) {
            font-size: 48px;

            span {
                font-size: 48px;
            }

        }

  ` ;
  const Heading4 = styled.h3`
  font-size: 32px;
  font-family: 'Eina02Regular';
  font-weight: 400;
  display: inline-block;
  margin: 0 auto;
  position: relative;

  @media(max-width: 960px) {
      font-size: 20px;
      
  }
  @media(max-width: 560px) {
    max-width: 300px;
    
}

` ;
  const TextYellow = styled.span`
        font-size: 90px;
        font-family: 'Eina02Regular';
        font-weight: 400;
        color: #FFC700;
  ` ;
  
  const SmallText = styled.p`
    font-size: 18px;
    font-family: 'Eina02Regular';
    margin: 50px auto 0;
`;



 const FieldGroup = styled.div`
       text-align: left;
       margin-bottom: 20px;
  `;
  const AudioContainer = styled.div`    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  `;

  const HeroFlexContainer = styled.div`    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;

    @media(max-width: 660px) {
        min-height: 550px;
    }

    .bottom-left-lines {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 197px;
        height: auto;

        @media(max-width: 560px) {
            width: 140px;
         
        }
    }
    .bottom-center-waves {
        position: absolute;
        left: 50%;
        bottom: 50px;
        width: 110px;
        height: auto;
        margin-left: - 55px;
    }
    .top-right-dots {
        position: absolute;
        right: 0;
        width: 165px;
        height: auto;
        top: -70px;

        @media(max-width: 560px) {
            width: 100px;
         
        }
    }
    .top-center-dots {
        position: absolute;
        left: 40%;
        width: 159px;
        height: auto;
        top: -10px;

        @media(max-width: 560px) {
            width: 100px;
         
        }
    }

    h1 {
        max-width: 680px;

        @media(max-width: 1370px) {
            font-size: 85px;
            line-height: 77px;
            min-width: 400px;
        }
        @media(max-width: 1150px) {
            font-size: 66px;
            line-height: 72px;
            max-width: 459px;
        }

        @media(max-width: 760px) {
            font-size: 56px;
            max-width: 280px;
            min-width: 0;
            line-height: 53px;
        }
        @media(max-width: 660px) {
            margin-top: 120px;
        }
        @media(max-width: 580px) {
            max-width: none;
        }
   
    }
  `;
  const BlueText = styled.span`    
    color: #413EFF
  `;
  const CardContainer = styled.div`    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 90px;
    flex-wrap: wrap;
  `;
  const HiwCard = styled.div`
    width: 375px;
    height: 280px;
    border-radius: 30px;
    background: #ffffff;
    padding-bottom: 50px;
    margin: 0 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    box-shadow: 16px 13px 45px 5px rgba(0, 0, 0, 0.2);


    @media(max-width: 760px) {
        height: 230px;
        width: 310px;
        padding-bottom: 30px;

    }
    
     P {
        font-size: 22px;
        color: #000000;
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;

        @media(max-width: 760px) {
            font-size: 16px;
        }
     } 
    
  `;
  const HiwIconContainer = styled.div`    
    border-bottom: 3px solid #ffffff;
    text-align: center;
    padding: 30px 50px 20px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;


    &.blue {
        background: #555AF6;
    } 
    &.yellow {
        background: #FFDC00;
    }  
    &.pink {
        background: #FF8687;
    }

    img {
        width: 80px;
        height: 80px;

        @media(max-width: 760px) {
            width: 70px;
            height: 70px;
        }
    }
  `;
  const RadioGroup = styled.div`    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 40px;
    margin-bottom: 40px;

    .radio {
        max-width: 40%;
        padding: 25px;
        border: 2px solid #CACACA;
        border-radius: 20px;
        text-align: left; 
        margin: 0 15px 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;

        @media(max-width: 980px) {
            max-width: 36%;
        }
        @media(max-width: 850px) {
            max-width: 100%;
        }

        @media(max-width: 780px) {
            margin-bottom: 25px;
        }

        &.active, &:hover {
            border-color: #12D175;
            background: #F5FFFB;
        }

        img {
            width: 50px;
            height: auto;
            margin-right 15px;
        }

        label {
            display: block;
            font-size: 18px;
            color: #888888;
            font-family: 'Eina02Regular';

            @media(max-width: 760px) {
                font-size: 16px;
            }

            input {
                display: none;
            }
        }
    }
    `;

    const Card = styled.div`
        height: 360px;
        width: 400px;
        position: relative;
        margin-bottom: 60px;
        box-shadow: 16px 13px 45px 5px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        margin: 10px 10px 30px;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
  ` ;

  const BottomBanner = styled.div`
       position: absolute;
       bottom: 0;
       left: 0;
       right: 0;
       height: 59px;
       z-index:10;
       padding: 18px;
       border-radius: 0 0 30px 30px;
       display: flex;
       flex-direction: column;
       justify-content: space-evenly;

       .audio-animation {
        position: relative;

        svg {
            margin-top: -55px;
        }
       }

       img {
        width: 60px;
        height: 60px;
       }

       &.active {
        top: 0;
        height: 324px;
        border-radius: 30px;
        bottom: 0;
        
       }
       .bottom-ctn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 95px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            padding: 0 20px;
            justify-content: space-between;
            align-items: center;
       }
  ` ;

    const MetaData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    
    ` ;

    const HumanImage = styled.img`
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    border-radius: 30px;
    `;

    const Title = styled.span`
        font-size: 18px;
        font-family: 'Eina02Semibold';
        font-weight: 800;
        display: block;
    `;
    const Name = styled.span`
        font-size: 18px;
        font-weight: 400;
        display: block;

    `;


export function Landing() {
    const [audio1, audio1Data] = useSound('https://app.lilokwi.com/static/Snorts_and_Giggles.m4a',{
        onend: ()=>{
            Mixpanel.track(`Listened Completely Snorts And Giggles`,{})
            setCurrentPlayingIndex(-1)
            playState = false;
        }
    })
    const [audio2, audio2Data] = useSound('https://app.lilokwi.com/static/songs-lullabies.mp3',{
        onend: ()=>{
            Mixpanel.track(`Listened Completely Sunshine of my Life`,{})
            setCurrentPlayingIndex(-1)
            playState = false;
        }
    })
    const [audio3, audio3Data] = useSound('https://app.lilokwi.com/static/Como_haces_Frijoles.mp3',{
        onend: ()=>{
            Mixpanel.track(`Listened Completely Frijoles`,{})
            setCurrentPlayingIndex(-1)
            playState = false;
        }
    })
    const [audio4, audio4Data] = useSound('https://app.lilokwi.com/static/Hype-Message.mp3',{
        onend: ()=>{
            Mixpanel.track(`Listened Completely Hype`,{})
            setCurrentPlayingIndex(-1)
            playState = false;
        }
    })
    const [audio5, audio5Data] = useSound('https://app.lilokwi.com/static/GettingPumpedBeforeaBigGame.mp3',{
        onend: ()=>{
            Mixpanel.track(`Listened Completely Inner Child`,{})
            setCurrentPlayingIndex(-1)
            playState = false;
        }
    })
    const [audio6, audio6Data] = useSound('https://app.lilokwi.com/static/our_culture.mp3',{
        onend: ()=>{
            Mixpanel.track(`Listened Completely Mumbai Trains`,{})
            setCurrentPlayingIndex(-1)
            playState = false;
        }
    })


    const [showText, setShowText] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [referralName, setReferralName] = useState('');

    const [userProfile, setUserProfile] = useState('Parent-to-Be');
    const [profileCreationReason, setProfileCreationReason] = useState('Make an \'I love you\' playlist');
    const [howDidYouHear, setHowDidYouHear] = useState('Facebook');
    const myRef = useRef(null)
    const [isChecked, setChecked] = useState(true);

    const isFirstRender = useRef(true)

    useEffect(() => {
        if (isFirstRender.current) {
            Mixpanel.track(`Site Visit`,{})
            isFirstRender.current = false;
            return;
           }
       }, []
       )

    const executeScroll = () => myRef.current.scrollIntoView()    
    // run this function from an event handler or an effect to execute scroll 

    let playState = false
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(-1)

    function openModal(event) {
        event.preventDefault();
        setModalOpen(true);
        document.body.style.overflow = "hidden";
      }
      
      function closeModal(event) {
        event.preventDefault();
        setFormSubmitted(false);
        setModalOpen(false);
        document.body.style.overflow = "visible";
      }

    //function to toggle between play and pause
    //tapping on a bird will start the recording, tapping on the same bird again will stop it, tapping on another bird while a recording is playing
    //will stop the previous recording and start the new one
    //need to get the URL from the birds and update the audio element with it
    const startOrPause = (index : number) => {
        Mixpanel.track(`Played ${data[index].title}`,{})
        setShowText(false);
        console.log(showText, 'show text!!');
        console.log(index)
        console.log("start or pause called")
        pauseAudio()
            
        if (!playState || (currentPlayingIndex != index)) {
            console.log(data[index])
            data[index].audioPlayFunction()
            setCurrentPlayingIndex(index)
            playState = true
        } else {
            setCurrentPlayingIndex(-1)
            playState = false
        }
    };

    // const clickOnBackground = (event) => {
    //     console.log("click on background")
    //     if (!event.target.className.includes("birdImage")) {
    //         pauseAudio()
    //         console.log("event.target contains bird")
    //     } else {
    //         console.log("doesn't contain bird")
    //     }
    //     // console.log(event.target.className )
    // }

    const pauseAudio = () => {
        for (const audioData of data) {
            audioData.audioMetadata.pause()
        }
        setCurrentPlayingIndex(-1)
    }

    const data = [
            {
                imageUrl: "snorts.png",
                title: "Snorts and Giggles",
                name: "by Baby Liam",
                location: "Chicago",
                // audioUrl: audio1,
                audioPlayFunction: audio1,
                audioMetadata : audio1Data,
                backgroundColor: 'rgba(239, 139, 229, 1)',
                backgroundColorOpacity: 'rgba(239, 139, 229, 0.8)'
            },
            {
                imageUrl: "sunshine.png",
                title: "Sunshine of my Life",
                name: "by Auntie Shay",
                location: "Minneapolis",
                audioPlayFunction: audio2,
                audioMetadata : audio2Data,
                backgroundColor: 'rgba(128, 213, 221, 1)',
                backgroundColorOpacity: 'rgba(128, 213, 221, 0.8)'
            },
            {
                imageUrl: "abulea.png",
                title: "¿Cómo Hacer Frijoles?",
                name: "by Marta y Abuela",
                location: "Houston",
                audioPlayFunction: audio3,
                audioMetadata : audio3Data,
                backgroundColor: 'rgba(255, 142, 87, 1)',
                backgroundColorOpacity: 'rgba(255, 142, 87, 0.8)'
            },
            {
                imageUrl: "hype.png",
                title: "Hype Message for Mama",
                name: "by Annika",
                location: "New York City",
                audioPlayFunction: audio4,
                audioMetadata : audio4Data,
                backgroundColor: 'rgba(233, 234, 78, 1)',
                backgroundColorOpacity: 'rgba(233, 234, 78, 0.8)'
            },
            {
                imageUrl: "game.png",
                title: "Channel Your Inner Child",
                name: "by Little Niko",
                location: "Washington, D.C",
                audioPlayFunction: audio5,
                audioMetadata : audio5Data,
                backgroundColor: 'rgba(255, 89, 105, 1)',
                backgroundColorOpacity: 'rgba(255, 89, 105, 0.8)'
            },
            {
                imageUrl: "nana.png",
                title: "Mumbai Trains",
                name: "by Nana",
                location: "Çeşme, Turkey",
                audioPlayFunction: audio6,
                audioMetadata : audio6Data,
                backgroundColor: 'rgba(0, 205, 124, 1)',
                backgroundColorOpacity: 'rgba(0, 205, 124, 0.8)'
            }
        ]

  let handleFormSubmit = async (e) => {
    
    e.preventDefault();
    console.log(e)

    Mixpanel.track(`Part 2 Submitted (Name And Email)`,{name: {name}, email: {email} , "User Profile": {userProfile}, "Radio Option": {radioOption}, "Referral Name" : {referralName}})

    try {
      let res = await fetch("https://lb.api.lilokwi.com/auth/v1/sendSlackMessage", {
        method: "POST",
        body: JSON.stringify({
          message: `${name}, ${email}, ${userProfile}, ${radioOption} and heard of Lilokwi from ${referralName} has signed up!`,
          channel: '#new_user_sign_up'
        }),
      });
      const result = await res.json();
      console.log("result from api request is ")
      console.log(result)
      setFormSubmitted(true);
      console.log(formSubmitted, 'form submiteed!!');
    //   setModalOpen(false)
    } catch (err) {
        alert("Sorry, we had trouble saving your details to the beta list programme!")
        console.log("error in making http request")
        console.log(err);
    }
  }

  const [radioOption, setRadioOption] = useState();

  const handleCheckBoxChange = (option: any) =>{
    setChecked(true);
    setRadioOption(option);
  }

  const radioOptions = [
    { value: 'Cherish the sounds of childhood--it goes so fast!', label: 'Cherish the sounds of childhood--it goes so fast!', image: '../human.png'},
    { value: 'Expose my kids to another language with audio', label: 'Expose my kids to another language with audio', image: '../world.png' },
    { value: 'Collect stories and memories from aging loved ones', label: 'Collect stories and memories from aging loved ones', image: '../tree.png' },
    { value: 'Something else I don’t see here', label: 'Something else I don’t see here', image: '../search.png' },
    { value: "Build hype lists for yourself and others", label: "Build hype lists for yourself and others", image: '../hear.png'},
  ];

    return (
        <div>
            <Background>
                <MaxGrid2>
                {modalOpen && 
                    <Modal>
                    <ModalOverlay>
                        <ModalBox>
                        <IconContainer>
                                <CloseIcon onClick={closeModal} src={closeIcon}></CloseIcon>
                            </IconContainer>
                            {!formSubmitted &&
                            <div>
                            <BoldHeading2>Sign Up for Beta</BoldHeading2>
                        
                            <BetaForm className="modal-form" onSubmit={handleFormSubmit}>
                                <div>
                                    <FieldGroup>
                                        <InputLabel>Name*</InputLabel>
                                        <Input type='text' value={name} placeholder='Name' onChange={(e) => setName(e.target.value)}></Input>
                                    </FieldGroup>
                                    <FieldGroup>
                                        <InputLabel>Email*</InputLabel>
                                        <Input type='text' value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)}></Input>
                                    </FieldGroup>

                                    <FieldGroup>
                                        <InputLabel>Referral name</InputLabel>
                                        <Input type='text' value={referralName} placeholder='Referral name' onChange={(e) => {
                                            setReferralName(e.target.value)
                                            }}></Input>
                                    </FieldGroup>

                                    
                                    <OutlineButton disabled={name.length <= 0 || email.length <= 0} type='submit'>Sign Up for Beta</OutlineButton>                     
                                </div>
                            </BetaForm>
                            </div>
                            }
                            {formSubmitted &&
                            <div>
                                <BoldHeading2>You’re on the list!</BoldHeading2>
                                <Heading4>The next step is to schedule an onboarding session with our team. </Heading4>
                                <SmallText>Please choose a time that works for you!</SmallText>
                                <a href="https://calendly.com/lilokwi_rupa/alpha-testing-lilokwi?month=2023-04" target="_blank" rel="noreferrer">
                                    <OutlineButton onClick={()=>{
                                        Mixpanel.track(`Clicked Calendly`,{})
                                    }}>Schedule</OutlineButton>
                                </a>
                            </div>

                            }
                        </ModalBox>
                    </ModalOverlay>
                    </Modal>
                }
                    <HeroFlexContainer>
                        <Container>
                            <Heading1><BlueText>Audio joy</BlueText> from the people you <BlueText>love</BlueText></Heading1>
                            <YellowButton onClick={()=>{
                                Mixpanel.track('Hero Signup',{})
                                executeScroll()
                                }}><LinkAnchor>Join Beta</LinkAnchor></YellowButton>
                        </Container>
                        <Hero src={heroGuy} alt="hero guy"/>
                        <img className="bottom-left-lines" src={lines} alt="" />
                        <img className="bottom-center-waves" src={waves} alt="" />
                        <img className="top-right-dots" src={yellowDots} alt="" />
                        <img className="top-center-dots" src={pinkDots} alt="" />

                    </HeroFlexContainer>
                </MaxGrid2>
            </Background>
            <Section2>
                <MaxGrid>
                    <Heading2>Voice recordings from friends and family that you want to save forever</Heading2>
                </MaxGrid>
                
            </Section2>
            <SectionAudio>
                <MaxGrid>
                    <AudioContainer>
                        {data.map((item, i) => (
                            <Card onClick={()=> {
                                    if(currentPlayingIndex === i){
                                        Mixpanel.track(`Paused ${item.title}`,{})
                                        pauseAudio()
                                    }else{
                                        startOrPause(i)
                                    }
                                }}>

                                <div style={{position:'relative', alignItems:'center'}}>
                                    <HumanImage className="humanImage" src={item.imageUrl} alt="bird"/>
                                </div>

                                <BottomBanner style={{backgroundColor: item.backgroundColorOpacity}} className={currentPlayingIndex === i ? 'active' : ''}>
                                    <div className='audio-animation'>
                                        {currentPlayingIndex === i && <Lottie animationData={audioLottie} loop={true} />}
                                    </div>
                                    <div className='bottom-ctn' style={{backgroundColor: item.backgroundColor}}>
                                        <MetaData>
                                            <Title>{item.title}</Title>
                                            <Name>{item.name}</Name>
                                        </MetaData>

                                        {currentPlayingIndex === i ? 
                                            <img className='playButton' style={{zIndex: 15}} onClick={()=> {
                                                // Mixpanel.track(`Paused ${item.title}`,{})
                                                // pauseAudio()
                                            }} src={pauseButton} />
                                            : <img className='pauseButton' style={{zIndex: 15}} src={playButton} />
                                        }
                                    </div>
                                </BottomBanner>
                            </Card>

                            // <AudioElement className = {"audioElement" + [i+1]}
                            //     key={i}
                            //     index={i}
                            //     title = {item.title}
                            //     name = {item.name}
                            //     location = {item.location}
                            //     imageUrl = {item.imageUrl}
                            //     backgroundColor={item.backgroundColor}
                            //     onClick = {(index : number) => startOrPause(index)}
                            // ></AudioElement>
                        ))}
                    </AudioContainer>
                </MaxGrid>   
            </SectionAudio>

            {/* <SectionVitamin>
                <div style={{ display: 'flex', width:'100%', }}>
                    <div style={{ width: '30%', height: '50%' }}>
                        <div style={{display: 'flex', alignItems: 'right', justifyContent: 'right', }}>
                            <p style={{ textAlign: 'right'}}>
                                <Heading3>YOUR</Heading3>
                                <TextYellow>VOICE</TextYellow>
                                <Heading3>IS A</Heading3>
                                <TextYellow>VITAMIN</TextYellow>
                            </p>
                        </div>
                    </div>
                    
                    <div style={{ width: '60%',  height: '50%'}}>
                        <div style={{justifyContent: 'center',}}>
                            <p style={{textAlign: 'justify' }}>
                                <span> As the world gets noisier, humans are getting lonelier.</span>
                                <span>Meaningful connection is still within reach. We just need a better way to listen. Lilokwi cuts out the noise and tunes into the voices that matter. It’s beautifully simple and surprisingly powerful.</span>
                                <span>With audio, less is more. Way more. This is unfiltered feeling that goes straight to the heart. Hype, healing, and happiness in your own words. The sounds of home, friends, and memories that you can reach for anytime. </span>
                            </p>
                        </div>

                    </div>
                </div>
            </SectionVitamin> */}

            <SectionVitamin>
                <MaxGrid>
                    <div className='flex-container'>
                        <Heading3>Your <TextYellow>voice</TextYellow> is a <TextYellow>Vitamin</TextYellow></Heading3>
                        <p>
                            <span> As the world gets noisier, humans are getting lonelier.</span>
                            <span>Meaningful connection is still within reach. We just need a better way to listen. Lilokwi cuts out the noise and tunes into the voices that matter. It’s beautifully simple and surprisingly powerful.</span>
                            <span>With audio, less is more. Way more. This is unfiltered feeling that goes straight to the heart. Hype, healing, and happiness in your own words. The sounds of home, friends, and memories that you can reach for anytime. </span>
                        </p>
                    </div>
                </MaxGrid>
            </SectionVitamin>

            <SectionHowItWorks>
                <MaxGrid>
                    <Heading2>How it works</Heading2>
                    <CardContainer>
                        <HiwCard>
                            <HiwIconContainer className='yellow'>
                                <img src={yellow} alt=""/>
                            </HiwIconContainer>
                            <p>Record on the app or share from Whatsapp or Voice Memo</p>
                        </HiwCard>
                        <HiwCard>
                            <HiwIconContainer className='blue'>
                                <img src={blue} alt=""/>
                            </HiwIconContainer>
                            <p>Tag and curate Lilokwis into playlists based on mood</p>
                        </HiwCard>
                        <HiwCard>
                            <HiwIconContainer className='pink'>
                                <img src={pink} alt=""/>
                            </HiwIconContainer>
                            <p>{`Replay to re-live the joy! No social media side effects :)`}</p>
                        </HiwCard>
                    </CardContainer>
                </MaxGrid>
            </SectionHowItWorks>
            <SectionSignUpForm ref={myRef}>
                <MaxGrid>
                    <Heading2>Join our Beta</Heading2>
                    <FormContainer>
                        <FormLabel>
                            I am a
                            <FormSelect value={userProfile} onChange={ (e) => {
                                Mixpanel.track(`View Persona Dropdown`,{})
                                Mixpanel.track(`Persona Selection`,{option: e.target.value})
                                setUserProfile(e.target.value)
                                }}>
                                <option value="Parent">Parent</option>
                                <option value="Caretaker">Caretaker</option>
                                <option value="Auntie/Uncle">Auntie / Uncle</option>
                                <option value="Grandparent">Grandparent</option>
                                <option value="College Student">College Student</option>
                                <option value="Human">Human</option>
                            </FormSelect>
                        </FormLabel>
                        <span className='RadioLabel'>and want to:</span>

                        
                            <RadioGroup>
                            {radioOptions.map((option)=>(
                                <div className={"radio " + (radioOption === option.value ? 'active' : '')}>
                           
                                    <img src={option.image} alt="" />
                                    <label key={option.value}>
                                        <input
                                        type="radio"
                                        value={option.value}
                                        onChange={(e)=> {
                                            Mixpanel.track(`Usecase selection`,{option: option.value})
                                            handleCheckBoxChange(option.value)
                                        }}
                                        checked={radioOption === option.value}
                                        />
                                        {option.value}
                                    </label>  
                                </div>
                            ))}
                            </RadioGroup>
                        
                        <OutlineButton disabled={!radioOption}  onClick={()=>{
                            Mixpanel.track(`Part 1 Submitted`,{})
                            openModal(event)
                            }}><LinkAnchor>Sign Up for Beta</LinkAnchor></OutlineButton>

                    </FormContainer>
                    
                </MaxGrid>
            </SectionSignUpForm> 
        </div>
    );
}