import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Eina/OpenType CFF Std/Eina01_Bold.otf';
import './fonts/Eina/OpenType CFF Std/Eina01_Regular.otf';
import './fonts/Eina/OpenType CFF Std/Eina01_SemiBold.otf';
import './fonts/Eina/OpenType CFF Std/Eina01_RegularItalic.otf';

import './fonts/Eina/OpenType CFF Std/Eina02_Bold.otf';
import './fonts/Eina/OpenType CFF Std/Eina02_Regular.otf';
import './fonts/Eina/OpenType CFF Std/Eina02_SemiBold.otf';
import './fonts/Eina/OpenType CFF Std/Eina02_RegularItalic.otf';

import './fonts/Eina/OpenType CFF Std/Eina03_Bold.otf';
import './fonts/Eina/OpenType CFF Std/Eina03_Regular.otf';
import './fonts/Eina/OpenType CFF Std/Eina03_SemiBold.otf';
import './fonts/Eina/OpenType CFF Std/Eina03_RegularItalic.otf';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
