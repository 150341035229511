import React from 'react';

const TermsOfService: React.FC = () => {
  return (

      <iframe
        src={`${process.env.PUBLIC_URL}/lilokwi-terms.html`}
        title="Terms of Service"
        style={{ border: 'none', width: '100%', height: '100%' }}
      />
  );
};

export default TermsOfService;
