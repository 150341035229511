import React from 'react';
import styled from 'styled-components';
import logo from '../logo-bird.png';
import { Mixpanel } from '../mixpanel';


const FooterContainer = styled.footer`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 40px;

    p, a {
        font-size: 16px;
        font-family: 'Eina02Semibold';

        @media(max-width: 560px) {
            font-size: 14px;
        }
     }

    a {
        color: #000000;
        text-decoration: none;

        &:hover {
            color: #FFDC00;
        }
    }
`;

const LogoImage = styled.img`
    width: 40px;
    height: auto;

    @media(max-width: 560px) {
        width: 30px;
    }
`
export function Footer() {
    return (
        <FooterContainer>
            <p>Lilokwi™ 2023</p>   
            <LogoImage src={logo} className="App-logo" alt="logo"></LogoImage>
            <a onClick={()=>{
                Mixpanel.track('Clicked Contact Us',{})
            }} href="mailto:hello@lilokwi.com">Contact us</a> 
        </FooterContainer>
    );
}