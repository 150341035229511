import React from 'react';
import { useSearchParams } from 'react-router-dom'
import logo from '../logo.png';

export function Onboarding() {
    const [searchParams] = useSearchParams();
    function copyCode(code:string| null) {
        if (code) navigator.clipboard.writeText(code);
    }
    return (
        <div> 
                <h1><img src={logo} className="App-logo" alt="logo" /></h1>
                <h1>Welcome!  You have been invited to experience Lilokwi</h1>
                <div>
                    <span>Step One</span><p/>
                    <span> After you install the application, you will need your Invite Code.  Please copy it for later</span><p/>
                    <h3><span>{searchParams.get("inviteId")}</span> <button onClick={() => copyCode(searchParams.get("inviteId"))}>Copy</button></h3>
                    <span>Step Two</span><p/>
                    <h3><a href="https://testflight.apple.com/join/bFfS34Ks">Install Lilokwi</a></h3><p/>
                </div>
        </div>
    );
}