import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components';
import birdWelcome1 from '../bird-welcome-1.png';
import birdWelcome2 from '../bird-welcome-2.png';
import bird1 from '../birds/Bird_Cockatoo@2x.png';
import bird2 from '../birds/Bird_Kingfisher@2x.png';
import bird3 from '../birds/Bird_Myna@2x.png';
import bird4 from '../birds/Bird_Vanga@2x.png';
import bird5 from '../birds/Bird@2x.png';
import bird6 from '../birds/Birdleft@2x.png';
import arrow from '../arrow.png';
import closeIcon from '../x@2x.png';
import bigCloud from '../bigcloud.png';
import smallCloud from '../smallcloud.png';

export function Onboarding2() {

    const [searchParams] = useSearchParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [bodyOverflowHidden, setBodyOverflowHidden] = useState(false);

    function copyCode(code:string| null) {
        if (code) navigator.clipboard.writeText(code);
    }

    function openModal(event) {
      event.preventDefault();
      setModalOpen(true);
      document.body.style.overflow = "hidden";
    }
    
    function closeModal(event) {
      event.preventDefault();
      setModalOpen(false);
      document.body.style.overflow = "visible";
    }
    

    function inviteCode() {
        return searchParams.get("in") || searchParams.get("inviteId")
    }


  

    const Background = styled.div`
        background: linear-gradient(#D3F6E8, #F8E9F8);
        width:100%;
        height:100%;
  ` ;
  const Container = styled.div`
    padding: 80px 30px 0;

    @media(max-width: 720px) {
        padding-top: 90px;
    }
  ` ;


    const Heading1 = styled.h1`
        font-size: 64px;
        font-family: 'Eina02Bold';
        margin-bottom: 10px;
        font-weight: 700;

        @media(max-width: 960px) {
            font-size: 56px;
        }
        @media(max-width: 460px) {
            font-size: 36px;
        }
        
  ` ;
    const ActionContainer = styled.div`
        max-width: 760px;
        border-radius: 10px;
        background: rgba(255,255,255,0.5);
        margin: 70px auto 0;
        position: relative;

  ` ;
  const TopBird1 = styled.img`
        position: absolute;
        left: -20px;
        top: -86px;
        max-width: 113px;
        width: 100%;
        height: auto;

        @media(max-width: 720px) {
          left: -4px;
          top: -36px;
          max-width: 49px;
        }
  ` ;
  const TopBird2 = styled.img`
        position: absolute;
        right: -10px;
        top: -141px;
        max-width: 110px;
        width: 100%;
        height: auto;

        @media(max-width: 720px) {
          top: -67px;
          max-width: 52px;
        }
  ` ;

  const PaddingContainer = styled.div`
        display: block;
        padding: 30px 25px;
  ` ;
  const Heading2 = styled.h2`
        font-size: 30px;
        font-weight: 300;
        font-family: 'Eina01Regular';
        max-width: 960px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 50px;

        @media(max-width: 960px) {
            font-size: 20px;
            
        }
        @media(max-width: 720px) {
          margin-bottom: 0;
        }
        @media(max-width: 520px) {
            font-size: 20px;
        }

  ` ;
  const BoldHeading2 = styled.h2`
        font-size: 26px;
        font-weight: 700;
        font-family: 'Eina02Bold';
        max-width: 960px;
        margin: 20px auto 30px;
        position: relative;

        @media(max-width: 960px) {
            font-size: 20px;
            
        }
        @media(max-width: 560px) {
          max-width: 300px;
          
      }

  ` ;
  const Heading3 = styled.h3`
    font-size: 24px;
    font-weight: 700;
    font-family: 'Eina02Bold';
    display:block;
    margin-top: 40px;

    @media(max-width: 960px) {
        font-size: 18px;
    }
    @media(max-width: 520px) {
        font-size: 18px;
    }
  ` ;
  const OutlineButton = styled.button`
    width: 230px;
    height: 60px;
    border: 2px solid #000000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background: transparent;
    margin-top: 10px;
    margin: 10px 10px 0;
    cursor: pointer;
    border-radius: 10px;

    &:hover {
        opacity: 0.7;
    }
  ` ;
  const LargeOutlineButton = styled.button`
    width: auto;
    padding: 20px;
    height: 60px;
    border: 2px solid #000000;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background: transparent;
    margin-top: 10px;
    margin: 10px 10px 0;
    cursor: pointer;
    border-radius: 10px;

    &:hover {
        opacity: 0.7;
    }
  ` ;
  const BirdsContainer = styled.div`
        position:relative;
        padding: 100px 30px 60px;
        min-height:250px;
        height:100%;

        @media(max-width: 460px) {
            padding-top: 60px;
        }
  ` ;
  const Bird1 = styled.img`
  position: absolute;
  left: 0;
  top: -5px;
  width: 110px;
  height: auto;

  &:hover {
    left: -10px;
    cursor: pointer;
  }

  @media(max-width:1170px) {
    width: 55px;
    top: -50px;
  }

  @media(max-width:760px) {
    display: none;
  }

`
const Bird2 = styled.img`
  position: absolute;
  right: 0;
  bottom: 0px;
  width: 120px;
  height: auto;

  &:hover {
    bottom: -10px;
    cursor: pointer;
  }

  @media(max-width: 1170px) {
    width: 90px
  }
  @media(max-width: 520px) {
    width: 60px
  }

`;
const Bird3 = styled.img`
  position: absolute;
  right: 0;
  top: 10px;
  width: 120px;
  height: auto;

  &:hover {
    right: -10px;
    cursor: pointer;
  }
  @media(max-width: 1170px) {
    top: -50px;
    width: 80px;
  }
  @media(max-width: 760px) {
    display: none;
  }

`;
const Bird4 = styled.img`
  position: absolute;
  right: 23%;
  bottom: 0px;
  width: 140px;
  height: auto;

  &:hover {
    bottom: -10px;
    cursor: pointer;
  }
  @media(max-width: 1170px) {
    width: 90px;
  }
  @media(max-width: 520px) {
    width: 70px;
  }

`;
const Bird5 = styled.img`
  position: absolute;
  left: 23%;
  bottom: 0px;
  width: 140px;
  height: auto;

  &:hover {
    bottom: -10px;
    cursor: pointer;
  }
  @media(max-width: 1170px) {
    width: 90px;
  }

  @media(max-width: 520px) {
    width: 70px;
  }

`;
const Bird6 = styled.img`
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 120px;
  height: auto;

  &:hover {
    bottom: -10px;
    cursor: pointer;
  }
  @media(max-width: 1170px) {
    width: 90px;
  }

  @media(max-width: 520px) {
    width: 60px;
  }

`;

const LinkAnchor = styled.a`
   color: #000000;
   text-decoration: none;
`;

const InviteCodeContainer = styled.div`
  
`;
const InviteText = styled.p`
    font-size: 18px;
    // font-weight: 600;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: Eina02Regular;
`;
const InviteCodeBox = styled.div`
    background: rgba(255,255,255,0.8);
    border-radius: 8px;
    max-width: 270px;
    width: 100%;
    margin: 0 auto 0;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Eina02bold"
  font-size: 20px;
  letter-spacing: 5%;

    @media(max-width: 520px) {
      max-width: 230px;
    }
    @media(max-width: 375px) {
      max-width: 210px;
    }
`;
const InviteCode = styled.span`
  font-family: 'Eina02Bold';
  font-size: 18px;
  letter-spacing: 2px;
  
`;
const CopyCode = styled.button`
  font-size: 16px;
  color: #000000;
  outline: none;
  background: none;
  border: none;
  position: relative;
`;
const InfoText = styled.span`
  font-size: 16px;
  color: #000000;
  display: block;
  margin-top: 10px;
  margin-bottom: 12px;
`;

const Arrow = styled.img`
  position: absolute;
  width: 75px;
  bottom: -70px;
  left: 50px;
  height: auto;
  display: block;
`;
const Modal = styled.div`
  
`;
const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ModalBox = styled.div`
  max-width: 560px;
  border-radius: 10px;
  background: rgba(255,255,255,1);
  position: relative;
  padding: 40px;

  @media(max-width: 760px) {
    margin: 0 20px;
    padding: 35px 25px;
  }

`;
const BoldParagraph = styled.p`
  display: block;
  font-weight: 600;
  font-size: 20px;
  font-family: 'Eina02Bold';
`;
const RegularParagraph = styled.p`
  display: block;
  margin-top: 30px;
  font-size: 20px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
const CloseIcon = styled.img`
  width: 24px;
  height: auto;
  margin-top: -15px;
  margin-right: -15px;
  cursor: pointer;
`;

const BoldLink = styled.a`
  font-family: 'Eina02SemiBold';
  font-size: 20px;
  color: #000000;
`;
const BigCloud = styled.img`
    width: 140px;
    position: absolute;
    top: 150px;
    left: 200px;

    @media(max-width: 720px) {
        width: 102px;
        top: 90px;
        left: 20px;
    }
` ;
const BigCloud2 = styled.img`
    width: 140px;
    position: absolute;
    bottom: 300px;
    left: -50px;
` ;
const BigCloud3 = styled.img`
    width: 140px;
    position: absolute;
    top: 400px;
    right: 200px;
` ;
const SmallCloud = styled.img`
    width: 72px;
    position: absolute;
    right: 0;
    bottom: 300px;
` ;


    return (
        <div>   
            <Background>
              {modalOpen &&
                <Modal>
                  <ModalOverlay>
                    <ModalBox>
                      <IconContainer>
                        <CloseIcon onClick={closeModal} src={closeIcon}></CloseIcon>
                      </IconContainer>
                      <BoldHeading2>Hello Android Friends!</BoldHeading2>
                      <RegularParagraph>Our Beta works a bit differently for y’all. <br></br>We need to first add your email to the list of testers.</RegularParagraph>
                      <BoldLink
                       href="mailto:hello@lilokwi.com?subject=Android Access to Lilokwi!" >Email Us for Access</BoldLink>
                      <RegularParagraph>Once we’ve admitted you, Install on Google Play!</RegularParagraph>
                      <LargeOutlineButton><LinkAnchor href="https://play.google.com/store/apps/details?id=com.lilokwi.app">Get Lilokwi for Android</LinkAnchor></LargeOutlineButton>
                    </ModalBox>
                  </ModalOverlay>
                </Modal>
              }  
                <BigCloud src={bigCloud} alt="cloud image"></BigCloud>
                <BigCloud2 src={bigCloud} alt="cloud image"></BigCloud2>
                <BigCloud3 src={bigCloud} alt="cloud image"></BigCloud3>
                <SmallCloud src={smallCloud} alt="cloud image"></SmallCloud>
                <Container>
                <Heading1>Welcome!</Heading1>
                <Heading2>Audio-only content made by the people you love.</Heading2>
                <BoldHeading2>You’re Invited to Our Beta Test
                        {/* <Arrow src={arrow} alt="arrow down"></Arrow> */}
                        </BoldHeading2>
                <ActionContainer>
                   <TopBird1 src={birdWelcome1}></TopBird1>
                        <TopBird2 src={birdWelcome2}></TopBird2>
                    <PaddingContainer>
                        <InviteCodeContainer>
                            <InviteText>Grab your Invite Code, and then download the app</InviteText>
                            <InfoText>You’ll need this at Sign Up. </InfoText>
                            <InviteCodeBox>
                                <InviteCode>{inviteCode()}</InviteCode>
                                <CopyCode onClick={() => copyCode(inviteCode())}>Copy</CopyCode>
                            </InviteCodeBox>
                            
                        </InviteCodeContainer>
                        <Heading3>Get the App</Heading3>
                        <OutlineButton><Link to="/install" style={{ textDecoration: 'none', color: '#000000', display: 'block' }}>iOS</Link></OutlineButton>
                        <OutlineButton onClick={openModal}><LinkAnchor>Android</LinkAnchor></OutlineButton>
                    </PaddingContainer>
                </ActionContainer>
                </Container>
                <BirdsContainer>
                
                    <Bird1 src={bird1} alt="bird"/>
                    <Bird2 src={bird2} alt="bird"/>
                    <Bird3 src={bird3} alt="bird"/>
                    <Bird4 src={bird4} alt="bird"/>
                    <Bird5 src={bird5} alt="bird"/>
                    <Bird6 src={bird6} alt="bird"/>
                </BirdsContainer>    
            </Background>  
        </div>
    );
}