import React from 'react';
import logo from '../logo.png';
import appIcon from '../app-icon.png';
import testflightIcon from '../testflight-icon.png';
import arrow from '../arrow.png';
import play from '../play.png';
import styled from 'styled-components';
import '../App.css';

export function Install() {


const Container = styled.div`
    padding: 40px 30px;
  
`;
const Heading1 = styled.h1`
    font-size: 50px;
    font-family: 'Eina02Bold';

    @media(max-width: 960px) {
        font-size: 46px;
    }
    @media(max-width: 760px) {
        font-size: 36px;
        margin-top: 65px;
    }
`;
const StepsContainer = styled.div`
   padding-top: 50px;

   @media(max-width: 760px) {
    padding-top: 35px;
   }
   
`;
const Step = styled.div`

   max-width: 800px;
   margin: 0 auto 45px;

`;

const StepMeta = styled.div`
    @media(max-width: 760px) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;
const StepDesc = styled.div`
    @media(max-width: 760px) {
        text-align: left;
    }
   

`;
const StepName = styled.div`
   font-family: "Eina02Bold";
   font-size: 23px;
   margin-top: 20px;

   @media(max-width: 760px) {
    margin-top: 0;
    margin-bottom: 5px;
   }

`;

const StepDescription = styled.p`
   font-size: 16px;

   @media(max-width: 760px) {
        margin: 0;
   }
`;
const StepCta = styled.button`
    width: 230px;
    height: 50px;
    border: 1px solid #000000;
    text-align: center;
    font-size: 16px;
    font-family: "Eina02Bold";
    font-weight: 600;
    background: transparent;
    cursor: pointer;
    border-radius: 10px;
    margin: 25px 0 0;

    &:hover {
    opacity: 0.7;
    }

    @media(max-width: 760px) {
        width: 100%;
    }
   
`;

const StepImg = styled.img`
   border-radius: 16px;
   max-width: 80px;
   width: 100%;
   height: auto;
   
   @media(max-width: 760px) {
        margin-right: 12px;
   }
`;
const StepNumber = styled.h3`
   font-size: 28px;
   font-family: "Eina02Bold";
   letter-spacing: 0.5px;
   text-transform: uppercase;

   @media(max-width: 760px) {
        text-align: left;
        margin: 0 0 23px;
   }
`;

const CustomFooter = styled.footer`
   height: 60px;
   text-align: center;
   font-size: 14px;
   padding: 20px 20px 50px;
`;

const ButtonLink = styled.a`
   color: #000000;
   text-decoration: none;
`;
    return (
        <div className="App">
            <Container>
                <Heading1>How to Install</Heading1>
                <StepsContainer>
                    <Step>
                        <StepNumber>Step 1</StepNumber>
                        <StepMeta>
                            <StepImg src={testflightIcon} alt="testflight icon"></StepImg>
                            <StepDesc>
                                <StepName>Install Testflight</StepName>
                                <StepDescription>After install come back to this screen and go to <b>Step 2</b>.
                                </StepDescription>
                            </StepDesc>
                        </StepMeta>
                        <StepCta><ButtonLink target="_blank" href="https://apps.apple.com/us/app/testflight/id899247664?mt=8">Go to App Store</ButtonLink></StepCta>
                    </Step>
                   
                    <Step>
                        <StepNumber>Step 2</StepNumber>
                        <StepMeta>
                            <StepImg src={appIcon} alt="Lilokwi icon"></StepImg>
                            <StepDesc>
                                <StepName>Install Lilokwi Here</StepName>
                                <StepDescription>If you installed Testflight, you can install Lilokwi.
                                </StepDescription>
                            </StepDesc>
                        </StepMeta>
                        <StepCta><ButtonLink href="https://testflight.apple.com/join/bFfS34Ks">Download</ButtonLink></StepCta>

                    </Step>
                </StepsContainer>
            </Container>
            <CustomFooter>
                <p>Testflight is a sandbox environment created by Apple for beta testing. Today, Lilokwi is powered and distributed by Testflight. Your feedback moves us closer to distributing through the App Store. Thank you! 💜</p>
            </CustomFooter>
      </div>
);
}
  